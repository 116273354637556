import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const appRoute = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // User Routes
    {
      path: 'user',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'google-authenticator', element: <GoogleAuthenticator />, permCode: 'gau' },
        { path: 'duty-scheduler', element: <DutyScheduler />, permCode: 'dsc' },
        { path: 'manage-admin', element: <ManageAdmin />, permCode: 'mad' },
        { path: 'manage-admin/form', element: <AdminForm />, permCode: 'mad' }, // n/a
        { path: 'manage-group', element: <ManageGroup />, permCode: 'mag' }, // n/a
        { path: 'manage-group/form', element: <GroupForm />, permCode: 'eag' }, // n/a
        { path: 'admin-amendment', element: <AdminAmendment />, permCode: 'ada' },
        { path: 'activity-log', element: <ActivityLog />, permCode: 'alg' },
        { path: 'inactive-log', element: <InactiveLog />, permCode: 'ilg' }, // n/a
        { path: 'account', element: <UserAccount /> },
      ],
    },

    // Loan Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'loans', element: <Loans /> },
        {
          path: 'loans/customer/info/:tabValue/:customerId',
          element: <MemberInfo />,
          permCode: 'vci',
        },
        //   { path: 'active', element: <ActiveLoans />, permCode: 'acl' },
        //   { path: 'listing', element: <LoanListing />, permCode: 'lls' },
        //   { path: 'overdue-listing', element: <OverdueLoanListing />, permCode: 'oll' },
        //   { path: 'bad-pay', element: <BadPayLoans />, permCode: 'bpl' },
        //   { path: 'non-performing', element: <NonPerformingLoan />, permCode: 'npl' },
      ],
    },

    // Repayment Routes
    {
      path: 'repayment',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'log', element: <RepaymentLog />, permCode: 'rpl' },
        { path: 'audit-log', element: <RepaymentLogAudit />, permCode: 'rla' },
        { path: 'manual-banking', element: <ManualBanking />, permCode: 'mbk' },
      ],
    },

    // STAFF
    {
      path: 'staff',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'commission', element: <StaffCommission />, permCode: 'sco' },
        { path: 'reward', element: <StaffReward />, permCode: 'sra' },
        { path: 'approval', element: <StaffApproval />, permCode: 'saa' },
      ],
    },

    // CUSTOMER
    {
      path: 'customer',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'list',
          element: <MemberList />, // permCode: 'mbl'
          permCode: 'mbl',
        },
        {
          path: 'edit',
          element: <MemberEdit />,
          // permCode: 'eky'
        },
        {
          path: 'info/:customerId',
          element: <MemberInfo />,
          permCode: 'vci',
        },
        {
          path: 'kyc-verify-list',
          element: <KYCVerifyList />, // permCode: 'kvl'
          permCode: 'kvl',
        },
        {
          path: 'blacklisted',
          element: <BlacklistedCustomer />, // permCode: 'blk',
          permCode: 'blc',
        },
        {
          path: 'commission-withdrawal-listing',
          element: <CommissionWithdrawalListing />,
          permCode: 'cwl',
        },
        {
          path: 'info/:tabValue/:customerId',
          element: <MemberInfo />,
          permCode: 'vci',
        },
      ],
    },

    // Expenses Route
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'expenses', element: <Expenses /> },
      ],
    },

    // SETTING
    {
      path: 'setting',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'loan-package', element: <LoanPackage />, permCode: 'vlp' },
        { path: 'company-bank-accounts', element: <CompanyBankAccounts />, permCode: 'vba' },
        { path: 'mobile-apps', element: <MobileAppsSetting /> },
        { path: 'promotion', element: <Promotion /> },
        { path: 'announcement-notif-list', element: <AnnouncementNotifList />, permCode: 'van' },
        { path: 'announcement-notif-list/add-new', element: <AnnouncementNotifForm />, permCode: 'aan' },
        { path: 'export-history', element: <ExportHistory />, permCode: 'exh' },
        { path: 'blacklisted-mobile', element: <BlacklistedMobile /> },
        // { path: 'hardware', element: <Hardware /> },
        { path: 'call', element: <Call /> },
        // { path: 'whatsapp-handler', element: <WhatsAppHandler />, permCode: 'cph' },
      ],
    },

    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <Dashboard />, permCode: 'dbd' },
        { path: 'calendar', element: <Calendar />, permCode: 'dbd' },
      ],
    },

    // Dashboard Routes
    // {
    //   path: 'dashboard',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    //     { path: 'app', element: <GeneralApp /> },
    //     { path: 'ecommerce', element: <GeneralEcommerce /> },
    //     { path: 'analytics', element: <GeneralAnalytics /> },
    //     { path: 'banking', element: <GeneralBanking /> },
    //     { path: 'booking', element: <GeneralBooking /> },

    //     {
    //       path: 'e-commerce',
    //       children: [
    //         { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
    //         { path: 'shop', element: <EcommerceShop /> },
    //         { path: 'product/:name', element: <EcommerceProductDetails /> },
    //         { path: 'list', element: <EcommerceProductList /> },
    //         { path: 'product/new', element: <EcommerceProductCreate /> },
    //         { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
    //         { path: 'checkout', element: <EcommerceCheckout /> },
    //       ],
    //     },
    //     {
    //       path: 'user',
    //       children: [
    //         { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
    //         { path: 'profile', element: <UserProfile /> },
    //         { path: 'cards', element: <UserCards /> },
    //         { path: 'list', element: <UserList /> },
    //         { path: 'new', element: <UserCreate /> },
    //         { path: ':name/edit', element: <UserCreate /> },
    //         { path: 'account', element: <UserAccount /> },
    //       ],
    //     },
    //     {
    //       path: 'invoice',
    //       children: [
    //         { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
    //         { path: 'list', element: <InvoiceList /> },
    //         { path: ':id', element: <InvoiceDetails /> },
    //         { path: ':id/edit', element: <InvoiceEdit /> },
    //         { path: 'new', element: <InvoiceCreate /> },
    //       ],
    //     },
    //     {
    //       path: 'blog',
    //       children: [
    //         { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
    //         { path: 'posts', element: <BlogPosts /> },
    //         { path: 'post/:title', element: <BlogPost /> },
    //         { path: 'new', element: <BlogNewPost /> },
    //       ],
    //     },
    //     {
    //       path: 'mail',
    //       children: [
    //         { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
    //         { path: 'label/:customLabel', element: <Mail /> },
    //         { path: 'label/:customLabel/:mailId', element: <Mail /> },
    //         { path: ':systemLabel', element: <Mail /> },
    //         { path: ':systemLabel/:mailId', element: <Mail /> },
    //       ],
    //     },
    //     {
    //       path: 'chat',
    //       children: [
    //         { element: <Chat />, index: true },
    //         { path: 'new', element: <Chat /> },
    //         { path: ':conversationKey', element: <Chat /> },
    //       ],
    //     },
    //     { path: 'calendar', element: <Calendar /> },
    //     { path: 'kanban', element: <Kanban /> },
    //     { path: 'permission-denied', element: <PermissionDenied /> },
    //   ],
    // },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];
  let filteredRoute = [];
  let permission = localStorage.getItem('perm');
  if (permission && permission !== 'undefined') {
    permission = JSON.parse(permission);
  } else {
    permission = [];
  }

  filteredRoute = appRoute.map((rt, idx) => {
    let newRt = { ...rt };
    if (rt?.children) {
      if (rt?.children.length > 0) {
        // const newChildren = rt.children.filter((f) => permission.includes(f.permCode));

        const newChildren = rt.children.map((child, chidIdx) => {
          if (child?.permCode && permission.indexOf(child.permCode) === -1) {
            return { ...child, element: <Page403 /> };
          }
          return child;
        });

        newRt = { ...rt, children: newChildren };
      }
    }

    return newRt;
  });

  return useRoutes(filteredRoute);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD
const Dashboard = Loadable(lazy(() => import('../views/Home/Dashboard')));

// USER
const GoogleAuthenticator = Loadable(lazy(() => import('../views/user/GoogleAuthenticator')));
const DutyScheduler = Loadable(lazy(() => import('../views/user/DutyScheduler')));
const ManageAdmin = Loadable(lazy(() => import('../views/user/ManageAdmin')));
const AdminForm = Loadable(lazy(() => import('../views/user/AdminForm')));
const ManageGroup = Loadable(lazy(() => import('../views/user/ManageGroup')));
const GroupForm = Loadable(lazy(() => import('../views/user/GroupForm')));
const AdminAmendment = Loadable(lazy(() => import('../views/user/AdminAmendment')));
const ActivityLog = Loadable(lazy(() => import('../views/user/ActivityLog')));
const InactiveLog = Loadable(lazy(() => import('../views/user/InactiveLog')));

// LOAN
const Loans = Loadable(lazy(() => import('../views/loan/Loans')));
// const ActiveLoans = Loadable(lazy(() => import('../views/loan/ActiveLoans')));
// const LoanListing = Loadable(lazy(() => import('../views/loan/LoanListing')));
// const OverdueLoanListing = Loadable(lazy(() => import('../views/loan/OverdueLoanListing')));
// const BadPayLoans = Loadable(lazy(() => import('../views/loan/BadPayLoans')));
// const NonPerformingLoan = Loadable(lazy(() => import('../views/loan/NonPerformingLoan')));

// REPAYMENT
const RepaymentLog = Loadable(lazy(() => import('../views/repayment/RepaymentLog')));
const RepaymentLogAudit = Loadable(lazy(() => import('../views/repayment/RepaymentLogAudit')));
const ManualBanking = Loadable(lazy(() => import('../views/repayment/ManualBanking')));

// STAFF
const StaffCommission = Loadable(lazy(() => import('../views/staff/StaffCommission')));
const StaffReward = Loadable(lazy(() => import('../views/staff/StaffReward')));
const StaffApproval = Loadable(lazy(() => import('../views/staff/StaffApproval')));

// CUSTOMER
const MemberList = Loadable(lazy(() => import('../views/customer/MemberList')));
const MemberEdit = Loadable(lazy(() => import('../views/customer/MemberEdit')));
const MemberInfo = Loadable(lazy(() => import('../views/customer/MemberInfo')));
const KYCVerifyList = Loadable(lazy(() => import('../views/customer/KYCVerifyList')));
const BlacklistedCustomer = Loadable(lazy(() => import('../views/customer/BlacklistedCustomer')));
const CommissionWithdrawalListing = Loadable(lazy(() => import('../views/customer/CommissionWithdrawalListing')));

// EXPENSES
const Expenses = Loadable(lazy(() => import('../views/expenses/Expenses')));

// SETTING
const LoanPackage = Loadable(lazy(() => import('../views/setting/LoanPackage')));
const CompanyBankAccounts = Loadable(lazy(() => import('../views/setting/CompanyBankAccounts')));
const MobileAppsSetting = Loadable(lazy(() => import('../views/setting/MobileAppsSetting')));
const Promotion = Loadable(lazy(() => import('../views/setting/Promotion')));
const AnnouncementNotifList = Loadable(lazy(() => import('../views/setting/AnnouncementNofiList')));
const AnnouncementNotifForm = Loadable(lazy(() => import('../views/setting/AnnouncementNotifForm')));
const ExportHistory = Loadable(lazy(() => import('../views/setting/ExportHistory')));
const BlacklistedMobile = Loadable(lazy(() => import('../views/setting/BlacklistedMobile')));
const Hardware = Loadable(lazy(() => import('../views/setting/Hardware')));
const Call = Loadable(lazy(() => import('../views/setting/Call')));
// const WhatsAppHandler = Loadable(lazy(() => import('../views/setting/WhatsAppHandler')));

//----------------------------------------------------------------------------------------------------------------------

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
