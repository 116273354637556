import { createSlice } from '@reduxjs/toolkit';
// utils
import conObj from '../../utils/connection-assistant';
import { getDateTime } from '../../utils/getMoment';
//
import { dispatch } from '../store';

const initialState = {
    isFetching: false,
    unreadCount: 0,
    notifications: [],
    unreadInactiveLogCount: 0,
    isAnyCustomerKycEditLocked: false,
    pendingManualBankingCount: 0
}

const slice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setUnreadCount(state, action) {
            state.unreadCount = action.payload;
        },
        setNotifications(state, action) {
            state.notifications = action.payload;
        },
        setIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        setUnreadInactiveLogCount(state, action) {
            state.unreadInactiveLogCount = action.payload;
        },
        setPendingManualBankingCount(state, action) {
            state.pendingManualBankingCount = action.payload;
        },
        readAllNotification(state) {
            state.notifications = state.notifications.map((notification => {
                if(!notification.isRead) {
                    notification.isRead = true;
                    notification.readAt = getDateTime();
                    state.unreadCount -= 1;
                }
                return notification;
            }));
        },
        readNotification(state, action) {
            state.notifications = state.notifications.map((notification) => {
                if(action.payload === notification.userNotificationId) {
                    notification.isRead = true;
                    notification.readAt = getDateTime();
                    state.unreadCount -= 1;
                }
                return notification;
            });
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {setUnreadCount, setNotifications, setUnreadInactiveLogCount, readAllNotification, readNotification, setIsAnyCustomerKycEditLocked, setPendingManualBankingCount} = slice.actions;

export const fetchNotifications = () => {
    dispatch(slice.actions.setIsFetching(true));
    return conObj.get("UserNotification")
    .then(({data: {items, unReadCount}} )=> {
        dispatch(slice.actions.setUnreadCount(unReadCount));
        dispatch(slice.actions.setNotifications(items));
    }).catch((error) => {
        console.log(error);
    }).finally(() => dispatch(slice.actions.setIsFetching(false)));
};