import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return date ? format(new Date(date), 'dd MMMM yyyy') : '';
}

export function fDateTime(date) {
  return date ? format(new Date(date), 'dd MMM yyyy HH:mm') : '';
}

export function fDateTimeHSuffix(date) {
  return date ? format(new Date(date), 'MMM dd yyyy p') : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fDateTimeSuffix(date) {
  return date ? format(new Date(date), 'yyyy-MM-dd HH:mm:ss') : '';
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
