import moment from 'moment-timezone';

// Display formats table: https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/

const offset = '+0800';

/**
 * Moment middleware
 * @param {String|Number} value
 * @returns {import("moment-timezone").Moment}
 */
export default function getMoment(value) {
  if (
    value != null &&
    (typeof value === 'string' || typeof value === 'number' || value instanceof Date) &&
    typeof value !== 'undefined'
  )
    return moment(value);

  return moment();
}

/**
 * @param {String|Number} value
 * @returns {String}
 */
export const getFromNow = (value) => getMoment(value).fromNow();

/**
 * @param {String|Number} value
 * @param {String} separator
 * @returns {String}
 */
export const getDateOnly = (value, separator = '/') => getMoment(value).format(`DD${separator}MM${separator}YYYY`);

/**
 * @param {String|Number} value
 * @param {String} separator
 * @returns {String}
 */
export const getTimeOnly = (value, separator = ':') => getMoment(value).format(`hh${separator}mm${separator}ss A`);

/**
 * @param {String|Number} value
 * @returns {String}
 */
export const getTimestamp = (value) => getMoment(value).valueOf();

/**
 * @param {String|Number} value
 * @returns {String}
 */
export const getDateTime = (value) => getMoment(value).format();

/**
 * @param {String|Number} value
 * @returns {String}
 */
 export const getDateAndTime = (value) => getMoment(value).format('YYYY-MM-DD HH:mm:ss');

/**
 * @param {String|Number} value
 * @returns {String}
 */
export const getMomentStartDateOnly = (value) => getMoment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss');

/**
 * @param {String|Number} value
 * @returns {String}
 */
export const getMomentEndDateOnly = (value) => getMoment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss');

/**
 * @param {String|Number} value
 * @returns {String}
 */
export const getDateYearFirst = (value) => moment(value).format('YYYY-MM-DD');
