import { Box, Button, DialogContent, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import CustomModal from '../../components/CustomModal';
import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import MediaPreviewModal from '../../components/MediaPreviewModal';
import { closeVoipInstruction } from '../../redux/slices/settings';
import { useDispatch, useSelector } from '../../redux/store';
import conObj from '../../utils/connection-assistant';

// 1. Guide user to browse to Web Client URL https://techero.cloudpbxp.beone.my/login (might have a different production URL)

// 2. Provide username & password for each admin to login to their own SIP extension account in the Web Client (for testing, login using kz@techero.co, Pw: Techero2022)

// 3. Guide user to download and install Yeastar Linkus Client Google Chrome Plugin https://chrome.google.com/webstore/detail/yeastar-linkus-for-google/ldkinnfkollhaghfaioheokmnkjejmpm?hl=en

// 4. The google chrome extension shall auto login once it detects that the URL in step #1 is logged in.

// 5. Then user can start click number to call already.

const DEFAULT_VOIP = {
  webClientUrl: '',
  voipLoginUsername: '',
  voipLoginPassword: '',
};

function VoipInstruction() {
  const { isOpenVoipInstruction } = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const [voipCredentials, setVoipCredentials] = useState(DEFAULT_VOIP);

  const [isShow, setIsShow] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [selectedMedia, setSelectedMedia] = useState(null);

  const [openPreview, setOpenPreview] = useState(false);

  const previewMedia = (url) => {
    setSelectedMedia({
      mediaType: 'image',
      signedUrl: url,
    });
    setOpenPreview(true);
  };

  useEffect(() => {
    conObj.get('Setting/voip').then((res) => {
      setVoipCredentials({ ...voipCredentials, ...res.data });
    });
  }, []);

  const copyCreds = (value) => {
    navigator.clipboard.writeText(value);
    enqueueSnackbar('Copied!', { preventDuplicate: false });
  };

  return (
    <CustomModal
      title="Your Call feature is not setup!"
      open={isOpenVoipInstruction}
      onClose={() => dispatch(closeVoipInstruction())}
    >
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="subtitle2">Please follow the steps below to setup your Call Feature:</Typography>

          <Typography variant="body2">
            1. Download and install&nbsp;
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/yeastar-linkus-for-google/ldkinnfkollhaghfaioheokmnkjejmpm?hl=en"
            >
              Yeastar Linkus Client Google Chrome Extension
            </Link>{' '}
            to your Google Chrome Browser
          </Typography>
          <Typography variant="body2">
            2. After the installation is done, login to{' '}
            <Link href={voipCredentials.webClientUrl} rel="noopener noreferrer" target="_blank">
              BeOne Client
            </Link>{' '}
            with your credentials{' '}
            <Button
              size="small"
              variant="outlined"
              onClick={() => setIsShow(!isShow)}
              endIcon={<Iconify icon={isShow ? 'akar-icons:eye' : 'akar-icons:eye-closed'} />}
            >
              {isShow ? 'Hide' : 'Show'} Credentials
            </Button>
          </Typography>
          {isShow && (
            <Stack>
              {voipCredentials?.voipLoginPassword && voipCredentials?.voipLoginUsername ? (
                <>
                  <Typography variant="body2">
                    Username: {voipCredentials.voipLoginUsername}{' '}
                    <Tooltip title="Copy Username" placement='top'>
                      <IconButton size="small" onClick={() => copyCreds(voipCredentials.voipLoginUsername)}>
                        <Iconify icon={'akar-icons:copy'} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <Typography variant="body2">
                    Password: {voipCredentials.voipLoginPassword}{' '}
                    <Tooltip title="Copy Password" placement='top'>
                      <IconButton size="small" onClick={() => copyCreds(voipCredentials.voipLoginPassword)}>
                        <Iconify icon={'akar-icons:copy'} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </>
              ) : (
                <Typography variant="body2" color={'warning.main'}>
                  <Iconify icon="akar-icons:info" /> Hmmm, seems like you don't have credentials yet.
                </Typography>
              )}
            </Stack>
          )}
          <Typography variant="body2">
            3. Click the <Iconify icon="ion:extension-puzzle" /> "Extensions" button located at the top right of your
            Google Chrome browser
          </Typography>
          <Typography variant="body2">
            4. A dropdown will appear that shows the extensions of your browser, find and click the "
            <b>Yeastar Linkus for Google</b>" extension
          </Typography>
          <Typography variant="body2">
            <b>Note:</b> You can also choose to pin the extension by pressing the "Pin"{' '}
            <Iconify icon={'eos-icons:push-pin-outlined'} /> button for quick access
          </Typography>
          {/* <Link href="#" onClick={() => previewMedia("/instructions/how-to-pin.png")}>Click for reference</Link> */}
          <Image
            src="/instructions/how-to-pin.png"
            sx={{ width: 300, cursor: 'pointer', alignSelf: 'center' }}
            onClick={() => previewMedia('/instructions/how-to-pin.png')}
          />
          <Typography variant="body2">
            5. The extension will now popup, Please choose "<b>Linkus Web Client</b>"
          </Typography>
          <Typography variant="body2">
            <b>Note:</b> If the login failed and said "Unable to connect to your Linkus Web Client...", go to{' '}
            <Link href={voipCredentials.webClientUrl} rel="noopener noreferrer" target="_blank">
              BeOne Client
            </Link>{' '}
            again and refresh the site until you see the extension's Dialer Popup
          </Typography>
          <Image
            src="/instructions/dialer.png"
            sx={{ width: 200, cursor: 'pointer', alignSelf: 'center' }}
            onClick={() => previewMedia('/instructions/dialer.png')}
          />
          <Typography variant="body2">
            6. If previous steps are done correctly, please refresh this site and try again.
          </Typography>
          <Typography variant="body2">
            <b>Note:</b> If you think you have done the steps correctly and still seeing this prompt, it might be because the
            number wasn't recognized yet by the extension. If you want to know if the button/number you are trying to
            call is ready, try to hover your cursor to the button/number periodically until you see a tooltip saying "Call 0######
            with Linkus Web Client". If that doesn't show please contact tech support for assistance. Thank you
          </Typography>
          <Image
            src="/instructions/tooltip.gif"
            sx={{ width: 300, cursor: 'pointer', alignSelf: 'center' }}
            onClick={() => previewMedia('/instructions/tooltip.gif')}
          />
        </Stack>
      </DialogContent>
      <MediaPreviewModal media={selectedMedia} open={openPreview} handleClose={() => setOpenPreview(false)} />
    </CustomModal>
  );
}

export default VoipInstruction;
