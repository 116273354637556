import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
// @mui
import { Box, Button, Checkbox, Chip, Collapse, IconButton, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
// utils
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { fDate, fDateTime } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';
// components
import Label from '../../components/Label';
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { TableMoreMenu } from '../../components/table';
import { allLangs } from '../../config';
import conObj from '../../utils/connection-assistant';
import { openConfirm } from '../../redux/slices/confirm';
import { APP_ORIGIN } from './AnnouncementNotifForm';
import { PATH_SETTING } from '../../routes/paths';

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&': {
      fontSize: '0.75rem'
  }
}))

const AnnouncementNotifTableRow = ({ row, onDeleteRow = () => {} }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const { announcementId, appOrigin, title, publishTo, publishAt, createdByUserName, updatedAt, isActive } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const [open, setOpen] = useState(false);

  const {enqueueSnackbar} = useSnackbar();

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleDelete = () => {
    conObj.delete(`Announcement/${announcementId}`)
    .then((response) => {
      onDeleteRow();
      enqueueSnackbar("Announcement deleted!");
    }).catch((error) => {
      if(error.response?.data?.title) enqueueSnackbar(error.response.data.title, {variant: "error"});
      else console.log(error);
    }).finally(() => {
      handleCloseMenu();
    });
  }

  return (
    <>
      <TableRow
        hover // selected={selected}
      >
        <TableCell><StyledTypography>{APP_ORIGIN[appOrigin] || "-"}</StyledTypography></TableCell>

        <TableCell><StyledTypography>{title || "-"}</StyledTypography></TableCell>

        <TableCell><StyledTypography>{publishTo || "-"}</StyledTypography></TableCell>

        <TableCell><StyledTypography>{fDate(publishAt) || "-"}</StyledTypography></TableCell>

        <TableCell><StyledTypography>{createdByUserName || "-"}</StyledTypography></TableCell>

        <TableCell><StyledTypography>{fDateTime(updatedAt) || "-"}</StyledTypography></TableCell>

        <TableCell>
          <Chip
              variant="outlined"
              color={isActive ? "primary": "error"}
              label={<Typography variant="caption">{isActive ? "Active": "Inactive"}</Typography>}
            />
        </TableCell>

        <TableCell
          align="center"
          sx={{
            position: 'sticky',
            right: 0,
            zIndex: 1,
            backgroundColor: 'transparent',
            backgroundImage: `linear-gradient(to right, transparent , ${theme.palette.background.paper}, ${theme.palette.background.paper})`,
          }}
        >

        <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    navigate(PATH_SETTING.announcementNotifForm, {state: {announcementId}})
                  }}
                  sx={{color: "primary.main"}}
                >

                  <Iconify icon={'carbon:view'} />

                  View

                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    openConfirm("Delete Announcement?", handleDelete);
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon="fluent:delete-12-filled" />
                  Delete
                </MenuItem>
              </>
            }
          />
        
        </TableCell>
        
      </TableRow>
    </>
  );
};

export default AnnouncementNotifTableRow;

AnnouncementNotifTableRow.propTypes = {
  row: PropTypes.object,
};
