// import React from 'react';
import axios from 'axios';
import { RefreshJwt } from '../guards/AuthGuard';
import { setSession } from './jwt';
// import { Redirect, useHistory } from 'react-router-dom';
// import app from '../App';

/**
 * @description
 * axios instance with default
 * baseUrl & headers etc. for global use
 */
const conObj = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // headers, without this, it will throw unsupported media type error
    'content-type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    Accept: 'application/json',
  },
});

conObj.interceptors.request.use((request) => {
  // Need to check if jwt already expired?

  //  Moved this piece of code here so that localStorage.getItem("jwt")
  //  is always getting the latest value.
  //  If follow old settings, still need to refresh the whole site to
  //  reflect the latest localStorage values

  if (!request.headers.common?.Authorization) {
    request.headers.Authorization = `Bearer ${localStorage.getItem('jwt')}`;
  }

  // saveLogs(request);
  return request;
});

conObj.interceptors.response.use(
  (response) => {
    //  saveLogs(response);
    if (localStorage.getItem('isAuthenticated') === 'true') {
      RefreshJwt()
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      const lastPath = window.location.hash;
      // sessionStorage.setItem('lastPath', lastPath.substring(1, lastPath.length));
      return window.location.replace('/');
    }
    if (error.response.status === 418) {
      localStorage.clear();
      sessionStorage.clear();
      delete axios.defaults.headers.common.Authorization;
      return window.location.replace('/404');
    }

    throw error;
  }
);

// function saveLogs(req_data) {
// const data = {
// secret: "secret",
// req_data: req_data,
// jwt: localStorage.getItem("jwt")
// };
// axios
// .post(process.env.REACT_APP_LOGGER_API, data, axiosConfig)
// .then(res => {
// //do nothing
// })
// .catch(err => {
// console.log("Something went wrong while calling save logs: " + err);
// });
// }

export const showResponseError = (error, enqueueSnackbar) => {
  if (!enqueueSnackbar) return console.error('enqueueSnackbar parameter is missing');
  const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
  const errorMessages = error?.response?.data?.errors;
  let errorMessage = error?.response?.data?.message || error?.response?.data?.internalMessage;

  if (typeof errorMessage === 'string') {
    try {
      errorMessage += `: ${[...error?.response?.data?.errorData].join(', ')}`;
    } catch (err) {
      /// ignore
    }
    enqueueSnackbar(errorMessage, snackbarOptions);
  } else if (errorMessages && Object.keys(errorMessages).length) {
    Object.keys(errorMessages).forEach((key) => enqueueSnackbar(errorMessages[key], snackbarOptions));
  } else enqueueSnackbar(`${error.message}`, snackbarOptions);

  return true;
};


export default conObj;
