import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import settingsReducer from './slices/settings';
import loanReducer from "./slices/loan";
import confirmReducer from "./slices/confirm";
import notificationReducer from "./slices/notification";
import customerReducer from "./slices/customer";
import imagePreviewReducer from "./slices/imagePreview";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  confirm: confirmReducer,
  settings: settingsReducer,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  loan: loanReducer,
  notification: notificationReducer,
  customer: customerReducer,
  imagePreview: imagePreviewReducer,
  product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
