import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/lab';

// ----------------------------------------------------------------------

RHFMobileDatePicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFMobileDatePicker({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MobileDatePicker
            inputFormat="dd/MM/yyyy"
            {...field}
            {...other}
            renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} variant="outlined" size="small" />}
        />
      )}
    />
  );
}
