import jwtDecode from 'jwt-decode';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';
import conObj from './connection-assistant';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decodedToken = jwtDecode(accessToken);
  const userId = localStorage.getItem('uid');

  const currentTime = Date.now() / 1000;

  if (decodedToken.exp < currentTime) {
    return false;
  }
  const timeLeft = decodedToken.exp - currentTime;
  // check if refresh needed, if the token left expire 10min
  if (timeLeft / 3600 < 0.1) {
    const requestUrl = `Anonymous/reauth/${userId}`;
    conObj
      .get(requestUrl)
      .then((res) => {
        if (res.status === 200) {
          const decodedToken = jwtDecode(res.data);
          const { perm, uid, uname } = decodedToken;

          // replace old jwt
          localStorage.setItem('jwt', res.data);
          localStorage.setItem('uid', uid);
          localStorage.setItem('uname', uname);
          localStorage.setItem('perm', perm);

          return true;
        }
        return true;
      })
      .catch((err) => {
        // unknow error
        console.error('ERROR: ', err);
      });

    // the jwt is still valid
    return true;
  }

  return true;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    // eslint-disable-next-line no-alert
    alert('Token expired');

    localStorage.removeItem('jwt');

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('jwt', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~5 days by minimals server
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('jwt');
    delete axios.defaults.headers.common.Authorization;
  }
};

const loggedIn = () => {
  // let isLoggedIn = false;
  const token = localStorage.getItem('jwt');
  // const compId = localStorage.getItem('compId');
  localStorage.setItem('isAuthenticated', true);
  if (token) {
    const decodedToken = jwtDecode(token);
    const { perm, uid, uname } = decodedToken;

    localStorage.setItem('uid', uid);
    localStorage.setItem('uname', uname);
    localStorage.setItem('perm', perm);
  }
};

export { isValidToken, setSession, loggedIn };
