import React, { useEffect, useState, useCallback } from 'react';
import * as Yup from 'yup';
import merge from 'lodash/merge';
import { useSnackbar } from 'notistack';
// @mui
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton, MobileDateTimePicker } from '@mui/lab';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from '../../redux/store';
import { createEvent, updateEvent, deleteEvent } from '../../redux/slices/calendar';
// routes
import { PATH_DASHBOARD, PATH_SETTING } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import {
  FormProvider,
  RHFTextField,
  RHFSwitch,
  RHFSelect,
  RHFEditor,
  RHFUploadMultiFile,
} from '../../components/hook-form';
import RHFMobileDatePicker from '../../components/hook-form/RHFMobileDatePicker';
import conObj from '../../utils/connection-assistant';
import RHFAutocomplete from '../../components/hook-form/RHFAutocomplete';
import { SUB_GROUPS } from './AnnouncementNofiList';
import EmptyContent from '../../components/EmptyContent';
import getMoment, { getDateYearFirst } from '../../utils/getMoment';

export const APP_ORIGIN = {
  gp: "Google Play",
  apk: "APK",
  hw: "HW",
  ios: "IOS"
}

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const getInitialValues = (event) => {
  const _event = {
    publishAt: '',
    isActive: '',
    appOrigin: '',
    // isRepeatDaily: '',
    title: '',
    titleMalay: '',
    titleChinese: '',
    titleThailand: '',
    uploadFileMediaType: 'image',
    uploadFileMediaTypeMalay: 'image',
    uploadFileMediaTypeChinese: 'image',
    uploadFileMediaTypeThailand: 'image',
    content: "",
    contentMalay: "",
    contentChinese: "",
    contentThailand: "",
    isToAllCustomers: "",
    isToSubgroupTypes: [],
    filesEn: [],
    filesBm: [],
    filesCn: [],
    filesThb: [],
  };

  if (event) {
    return merge({}, _event, event);
  }

  return _event;
};

export const ALLOWED_FILE_TYPES = {
  image: "image/png, image/gif, image/jpeg, image/bmp",
  doc: "application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf",
  video: "video/mp4"
};

const FILE_TYPES = {
  EN: ALLOWED_FILE_TYPES.image,
  BM: ALLOWED_FILE_TYPES.image,
  CN: ALLOWED_FILE_TYPES.image,
  TH: ALLOWED_FILE_TYPES.image
};

const AnnouncementNotifForm = ({ event }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [fileTypes, setFileTypes] = useState(FILE_TYPES);

  const navigate = useNavigate();

  const location = useLocation();

  const { themeStretch } = useSettings();

  const [selectedRow, setSelectedRow] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const EventSchema = Yup.object().shape({
    publishAt: Yup.date().required("Publish At is required"),
    // isRepeatDaily: Yup.bool().required("Repeat Daily is required"),
    title: Yup.string().max(255).required("Title is required"),
    content: Yup.string().max(5000).required("Content is required"),
    titleChinese: Yup.string().max(255),
    contentChinese: Yup.string().when("titleChinese", (titleChinese, schema) => titleChinese ? schema.max(5000).required("Content is required") : schema.max(5000)),
    titleMalay: Yup.string().max(255),
    contentMalay: Yup.string().when("titleMalay", (titleMalay, schema) => titleMalay ? schema.max(5000).required("Content is required") : schema.max(5000)),
    titleThailand: Yup.string().max(255),
    contentThailand: Yup.string().when("titleThailand", (titleThailand, schema) => titleThailand ? schema.max(5000).required("Content is required") : schema.max(5000)),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(event),
  });
  
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const fetchAnnouncement = async (id) => {
    try {
      setIsLoading(true);
      const fileFetcher = (data, inputName) => {
        const promises = data.map((file) => axios.get(file.fileUrl, {responseType: "blob", headers: {'Access-Control-Allow-Origin': '*'}}).then((response) => {
            const finalFile = new File([response.data], file.filename.split("/").pop());
            setValue(inputName, [...values[inputName], Object.assign(finalFile, {preview: file.fileUrl})]);
          }));

        return Promise.all(promises);
      };

      const response = await conObj.get(`Announcement/${id}`)

      const {data} = response;
      setSelectedRow(data);
      Object.keys(data).forEach((key) => setValue(key, data[key]));
      if(data.isToSubgroupTypes) {
        const group = data.isToSubgroupTypes.split(",").map(value => SUB_GROUPS.find(x => x.value === value));
        console.log(group);
        setValue("isToSubgroupTypes", group);
      } if (data.announcementMedias) {
        await fileFetcher(data.announcementMedias, "filesEn");
      } if (data.announcementMediasChinese) {
        await fileFetcher(data.announcementMediasChinese, "filesCn");
      } if (data.announcementMediasMalay) {
        await fileFetcher(data.announcementMediasMalay, "filesBm");
      } if (data.announcementMediasThailand) {
        await fileFetcher(data.announcementMediasThailand, "filesThb");
      } 
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }

      // .then(response => {
        
      // }).catch(error => console.log(error))
      // .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if(location?.state?.announcementId) {
      fetchAnnouncement(location.state.announcementId);
      setIsEdit(true);
    }
    return () => {
      setSelectedRow(null);
      setIsEdit(false);
    }
  }, []);

  useEffect(() => {
    if(values.uploadFileMediaType) setFileTypes(prevState => ({...prevState, EN: ALLOWED_FILE_TYPES[values.uploadFileMediaType]}));
  }, [values.uploadFileMediaType]);

  useEffect(() => {
    if(values.uploadFileMediaTypeMalay) setFileTypes(prevState => ({...prevState, BM: ALLOWED_FILE_TYPES[values.uploadFileMediaTypeMalay]}));
  }, [values.uploadFileMediaTypeMalay]);

  useEffect(() => {
    if(values.uploadFileMediaTypeChinese) setFileTypes(prevState => ({...prevState, CN: ALLOWED_FILE_TYPES[values.uploadFileMediaTypeChinese]}));
  }, [values.uploadFileMediaTypeChinese]);

  useEffect(() => {
    if(values.uploadFileMediaTypeThailand) setFileTypes(prevState => ({...prevState, TH: ALLOWED_FILE_TYPES[values.uploadFileMediaTypeThailand]}));
  }, [values.uploadFileMediaTypeThailand]);

  /**
   * @param {{filesEn: Array<File>, filesBm: Array<File>, filesCn: Array<File>, filesThb: Array<File>}} data 
   */
  const onSubmit = async ({
    publishAt,
    isActive,
    appOrigin,
    // isRepeatDaily,
    isToAllCustomers,
    isToSubgroupTypes,
    title,
    titleMalay,
    titleChinese,
    titleThailand,
    uploadFileMediaType,
    uploadFileMediaTypeMalay,
    uploadFileMediaTypeChinese,
    uploadFileMediaTypeThailand,
    content,
    contentMalay,
    contentChinese,
    contentThailand,
    filesEn,
    filesBm,
    filesCn,
    filesThb
  }) => {
    try {
        const newEvent = {
          publishAt: getDateYearFirst(publishAt),
          isActive,
          appOrigin,
          // isRepeatDaily,
          isToAllCustomers,
          isToSubgroupTypes: isToSubgroupTypes ? isToSubgroupTypes.map(group => (group.value)).join(",") : "",
          title,
          titleMalay,
          titleChinese,
          titleThailand,
          uploadFileMediaType,
          uploadFileMediaTypeMalay,
          uploadFileMediaTypeChinese,
          uploadFileMediaTypeThailand,
          content,
          contentMalay,
          contentChinese,
          contentThailand,
          uploadFileNames: null,
          uploadFileExtensions: null,
          uploadFileNamesMalay: null,
          uploadFileExtensionsMalay: null,
          uploadFileNamesChinese: null,
          uploadFileExtensionsChinese: null,
          uploadFileNamesThailand: null,
          uploadFileExtensionsThailand: null,
        };

        if(filesEn.length){
          newEvent.uploadFileNames = filesEn.map(file => file.name);
          newEvent.uploadFileExtensions = filesEn.map(file => file.name.split(".").pop());
        }
        if(filesBm.length){
          newEvent.uploadFileNamesMalay = filesBm.map(file => file.name);
          newEvent.uploadFileExtensionsMalay = filesBm.map(file => file.name.split(".").pop());
        }
        if(filesCn.length){
          newEvent.uploadFileNamesChinese = filesCn.map(file => file.name);
          newEvent.uploadFileExtensionsChinese = filesCn.map(file => file.name.split(".").pop());
        }
        if(filesThb.length){
          newEvent.uploadFileNamesThailand = filesThb.map(file => file.name);
          newEvent.uploadFileExtensionsThailand = filesThb.map(file => file.name.split(".").pop());
        }

        const response = await conObj.post("Announcement", newEvent);
        const uploadPromises = [];
        if(response.data.announcementMediaSignedUrls && response.data.announcementMediaSignedUrls.length) {
          response.data.announcementMediaSignedUrls.forEach((value, idx) => {
            uploadPromises.push(axios.put(value, filesEn[idx], {headers: { "Content-Type": filesEn[idx].type, 'Access-Control-Allow-Origin': '*' }}));
          });
        }
        if(response.data.announcementMediaSignedUrlsMalay && response.data.announcementMediaSignedUrlsMalay.length) {
          response.data.announcementMediaSignedUrlsMalay.forEach((value, idx) => {
            uploadPromises.push(axios.put(value, filesBm[idx], {headers: { "Content-Type": filesBm[idx].type, 'Access-Control-Allow-Origin': '*' }}));
          });
        }
        if(response.data.announcementMediaSignedUrlsChinese && response.data.announcementMediaSignedUrlsChinese.length) {
          response.data.announcementMediaSignedUrlsChinese.forEach((value, idx) => {
            uploadPromises.push(axios.put(value, filesCn[idx], {headers: { "Content-Type": filesCn[idx].type, 'Access-Control-Allow-Origin': '*' }}));
          });
        }
        if(response.data.announcementMediaSignedUrlsThailand && response.data.announcementMediaSignedUrlsThailand.length) {
          response.data.announcementMediaSignedUrlsThailand.forEach((value, idx) => {
            uploadPromises.push(axios.put(value, filesThb[idx], {headers: { "Content-Type": filesThb[idx].type, 'Access-Control-Allow-Origin': '*' }}));
          });
        }

        if(uploadPromises.length) await Promise.all(uploadPromises);

        enqueueSnackbar('Create success!');
        reset();
        navigate(PATH_SETTING.announcementNotifList);
    } catch (error) {
      if(error.response?.data?.title) enqueueSnackbar(error.response.data.title, {variant: "error"});
      else console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles, inputName) => {
      const images = values[inputName] || [];

      setValue(inputName, [
        ...images,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    [setValue, values]
  );

  const handleRemoveAll = (inputName) => {
    setValue(inputName, []);
  };

  const handleRemove = (inputName, file) => {
    const filteredItems = values[inputName]?.filter((_file) => _file !== file);
    setValue(inputName, filteredItems);
  };

  if(isLoading) {
    return (
        <Backdrop
            sx={{ color: 'primary.main', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
}

if(location.state?.announcementId && !selectedRow) {
    return <Page title="Announcement ">
        <EmptyContent
        title="Announcement Not Found"
        sx={{
          '& span.MuiBox-root': { height: 160 },
        }}
      />
    </Page>;
}

  return (
    <Page title="Add New Announcement/Notification">
    <Container
      maxWidth={themeStretch ? false : 'xl'}
      sx={{ paddingLeft: '0 !important', paddingRight: '0 !important' }}
    >
      <HeaderBreadcrumbs
        heading={`${selectedRow ? "View" : "Add"} Announcement/Notification`}
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Announcement/Notification List', href: PATH_SETTING.announcementNotifList },
          { name: `${selectedRow ? "View" : "Add"} Announcement` },
        ]}
      />

      <Card sx={{ py: 3, px: 2 }}>
        {/* <CardHeader title="" /> */}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <RHFMobileDatePicker
                name="publishAt"
                label="Publish Date"
                disabled={isEdit}
              />
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <RHFSelect name="isActive" label="Status" size="small" disabled={isEdit}>
                <option value="" />
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </RHFSelect>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <RHFSelect name="appOrigin" label="Apps Origin" size="small" disabled={isEdit}>
                <option value="" />
                {Object.keys(APP_ORIGIN).map((key, idx) => <option key={idx} value={key}>{APP_ORIGIN[key]}</option>)}
              </RHFSelect>
            </Grid>

            {/* <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <RHFSelect name="isRepeatDaily" label="Repeat Daily" size="small" disabled={isEdit}>
                <option value="" />
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </RHFSelect>
            </Grid> */}

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <RHFSelect name="isToAllCustomers" label="Send to all Customers?" size="small" disabled={isEdit}>
                <option value="" />
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </RHFSelect>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              {/* <RHFTextField type="text" name="isToSubgroupTypes" label="Subgroup Types" size="small" /> */}
              {!values.isToAllCustomers || values.isToAllCustomers === "false" ? 
              <RHFAutocomplete
                multiple
                name="isToSubgroupTypes"
                options={SUB_GROUPS}
                getOptionLabel={(option) => option.title}
                onChange={(e, value) => setValue("isToSubgroupTypes", value)}
                label="Sub Group Types"
                variant="outlined"
                size="small"
                fullWidth
                disabled={isEdit}
              /> : ""}
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} />

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Typography variant="h4" sx={{ my: 2 }}>
                Notification Details (English)
              </Typography>
              <RHFTextField name="title" label="Title" size="small" fullWidth disabled={isEdit} />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Typography variant="h4" sx={{ my: 2 }}>
                Notification Details (Bahasa Melayu)
              </Typography>
              <RHFTextField name="titleMalay" label="Title" size="small" fullWidth disabled={isEdit} />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <RHFSelect name="uploadFileMediaType" label="File Type" placeholder="File Type" size="small" sx={{ mb: 2 }} disabled={isEdit}>
                {/* <option value="" /> */}
                <option value="image">Image</option>
                <option value="doc">Document</option>
                <option value="video">Video</option>
              </RHFSelect>

              <RHFUploadMultiFile
                // showPreview
                name="filesEn"
                accept={fileTypes.EN}
                maxSize={3145728}
                onDrop={(files) => handleDrop(files, "filesEn")}
                onRemove={(file) => handleRemove("filesEn", file)}
                onRemoveAll={() => handleRemoveAll("filesEn")}
                onUpload={() => console.log('ON UPLOAD')}
                disabled={isEdit}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <RHFSelect name="uploadFileMediaTypeMalay" label="File Type" placeholder="File Type" size="small" sx={{ mb: 2 }} disabled={isEdit}>
                {/* <option value="" /> */}
                <option value="image">Image</option>
                <option value="doc">Document</option>
                <option value="video">Video</option>
              </RHFSelect>

              <RHFUploadMultiFile
                // showPreview
                name="filesBm"
                accept={fileTypes.BM}
                maxSize={3145728}
                onDrop={(files) => handleDrop(files, "filesBm")}
                onRemove={(file) => handleRemove("filesBm", file)}
                onRemoveAll={() => handleRemoveAll("filesBm")}
                onUpload={() => console.log('ON UPLOAD')}
                sx={{ mb: 3 }}
                disabled={isEdit}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <LabelStyle>Content</LabelStyle>
              <RHFEditor simple name="content" disabled={isEdit} />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <LabelStyle>Content</LabelStyle>
              <RHFEditor simple name="contentMalay" disabled={isEdit} />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Typography variant="h4" sx={{ my: 2 }}>
                Notification Details (Chinese)
              </Typography>
              <RHFTextField type="text" name="titleChinese" label="Title" size="small" disabled={isEdit} />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Typography variant="h4" sx={{ my: 2 }}>
                Notification Details (Thailand)
              </Typography>
              <RHFTextField type="text" name="titleThailand" label="Title" size="small" disabled={isEdit} />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <RHFSelect name="uploadFileMediaTypeChinese" label="File Type" placeholder="File Type" size="small" sx={{ mb: 2 }} disabled={isEdit}>
                {/* <option value="" /> */}
                <option value="image">Image</option>
                <option value="doc">Document</option>
                <option value="video">Video</option>
              </RHFSelect>

              <RHFUploadMultiFile
                // showPreview
                name="filesCn"
                accept={fileTypes.CN}
                maxSize={3145728}
                onDrop={(files) => handleDrop(files, "filesCn")}
                onRemove={(file) => handleRemove("filesCn", file)}
                onRemoveAll={() => handleRemoveAll("filesCn")}
                onUpload={() => console.log('ON UPLOAD')}
                disabled={isEdit}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <RHFSelect name="uploadFileMediaTypeThailand" label="File Type" placeholder="File Type" size="small" sx={{ mb: 2 }} disabled={isEdit}>
                {/* <option value="" /> */}
                <option value="image">Image</option>
                <option value="doc">Document</option>
                <option value="video">Video</option>
              </RHFSelect>

              <RHFUploadMultiFile
                // showPreview
                name="filesThb"
                accept={fileTypes.TH}
                maxSize={3145728}
                onDrop={(files) => handleDrop(files, "filesThb")}
                onRemove={(file) => handleRemove("filesThb", file)}
                onRemoveAll={() => handleRemoveAll("filesThb")}
                onUpload={() => console.log('ON UPLOAD')}
                sx={{ mb: 3 }}
                disabled={isEdit}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <LabelStyle>Content</LabelStyle>
              <RHFEditor simple name="contentChinese" disabled={isEdit} />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <LabelStyle>Content</LabelStyle>
              <RHFEditor simple name="contentThailand" disabled={isEdit} />
            </Grid>
          </Grid>
          {!selectedRow ? 
          <Stack alignItems="flex-start" sx={{ mt: 3 }}>
          <div>
            <LoadingButton
              type="submit"
              variant="contained" 
              loading={isSubmitting}
              disabled={isSubmitting}
              color="primary"
            >
              Submit
            </LoadingButton>
            <LoadingButton
              variant="outlined" // loading={isSubmitting}
              color="primary"
              sx={{ ml: 1 }}
            >
              Reset
            </LoadingButton>
          </div>
        </Stack> 
        : ""}
        </FormProvider>

      </Card>
    </Container>
    </Page>
  );
};

export default AnnouncementNotifForm;
