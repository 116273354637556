import { createSlice } from "@reduxjs/toolkit";

export const MEMBER_LIST_FILTERS = {
  dateFrom: null,
  dateTo: null,
  customerId: '',
  mobileNo: '',
  ic: '',
  fullName: '',
  loanQuantity: '',
  referralId: '',
  referralMobileNo: '',
  reloanStatus: '',
  status: '',
  loanApplicationStatus: '',
};

const initialState = {
  memberListFilters: MEMBER_LIST_FILTERS,
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setMemberListFilters(state, action) {
      state.memberListFilters = action.payload;
    },
    resetMemberListFilters(state) {
      state.memberListFilters = MEMBER_LIST_FILTERS;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setMemberListFilters,
  resetMemberListFilters,
} = slice.actions;