import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import conObj from '../utils/connection-assistant';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  RefreshJwt();

  return <>{children}</>;
}

export function RefreshJwt() {
  const accessToken = localStorage.getItem('jwt');

  if (accessToken) {
    const decodedToken = jwtDecode(accessToken);
    const userId = localStorage.getItem('uid');

    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      return false;
    }

    console.log((decodedToken.exp - Date.now() / 1000) / 3600)
    // check if refresh needed, if the token left expire 10min
    if ((decodedToken.exp - Date.now() / 1000) / 3600 < 0.1) {
      const requestUrl = `Anonymous/reauth/${userId}`;
      conObj
        .get(requestUrl)
        .then((res) => {
          if (res.status === 200) {
            const decodedToken = jwtDecode(res.data);
            const { perm, uid, uname } = decodedToken;

            // replace old jwt
            localStorage.setItem('jwt', res.data);
            localStorage.setItem('uid', uid);
            localStorage.setItem('uname', uname);
            localStorage.setItem('perm', perm);

            return true;
          }
          return true;
        })
        .catch((err) => {
          // unknow error
          console.error('ERROR: ', err);
        });
    }
  }
}
