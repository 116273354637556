import PropTypes from 'prop-types';
// @mui
import { Dialog, DialogTitle, DialogActions, Typography, IconButton } from '@mui/material';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

CustomModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  actions: PropTypes.node,
  onClose: PropTypes.func,
  hasClose: PropTypes.bool,
  children: PropTypes.node,
  isBackdropClosable: PropTypes.bool
};

export default function CustomModal({children, title = "", actions, open, onClose, hasClose = true, isBackdropClosable = true, ...other }) {

  const handleClose = (e, r) => {
    if (!isBackdropClosable && r === 'backdropClick') {
      return false;
    }

    if (!isBackdropClosable && r === 'escapeKeyDown') {
      return false;
    }

    onClose(e,r);
  }

  return (
    <Dialog open={open} onClose={handleClose} {...other}>
      
      {hasClose && 
          <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex:1,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon={"ep:close"} />
        </IconButton>
      }
      {title && <DialogTitle>
        {title}
      </DialogTitle>}
      {children}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}
