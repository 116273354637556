import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  TextareaAutosize,
  DialogContent,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import { useIdleTimer } from 'react-idle-timer';
import { useSnackbar } from 'notistack';
import Iconify from '../../components/Iconify';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { closeCall, closeHardware, openVoipInstruction } from '../../redux/slices/settings';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';

import { DialogAnimate } from '../../components/animate';

import conObj from '../../utils/connection-assistant';
import CustomConfirm from '../../components/CustomConfirm';
import VoipInstruction from '../../views/setting/VoipInstruction';
import CustomModal from '../../components/CustomModal';
import ClickToCall from '../../components/ClickToCall';
import { fMobileNumber } from '../../utils/formatNumber';
// import { fetchPendingLoanCount } from '../../redux/slices/loan';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const dispatch = useDispatch();

  const { isOpenCall, isOpenHardware, isCallPagePing } = useSelector((state) => state.settings);

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const [pingInterval, setPingInterval] = useState(300000);

  const [inactiveId, setInactiveId] = useState(-1);

  const handleCloseCall = () => {
    dispatch(closeCall());
  };

  const handleCloseHardware = () => {
    dispatch(closeHardware());
  };

  const onPrompt = () => {
    // Fire a Modal Prompt
    console.log('onPrompt');
  };

  /**
   * User is idle for x min
   */
  const onIdle = () => {
    console.log('onIdle');

    conObj
      .post('UserInactiveHistory/idle')
      .then((res) => {
        if (res.status === 201) {
          setInactiveId(res.data.userInactiveHistoryId);
        }
      })
      .catch((e) => {
        console.log(`Error: ${e.message}`);
      });
  };

  /**
   * User become active after being idle
   */
  const onActive = (event) => {
    console.log('onActive');
    conObj
      .put(`UserInactiveHistory/idle-moved/${inactiveId}`)
      .then((res) => {
        if (res.status === 200) {
          // reset back to -1
          setInactiveId(-1);
        }
      })
      .catch((e) => {
        console.log(`Error: ${e.message}`);
      });
  };

  const onAction = (event) => {
    // Do something when a user triggers a watched event
    // console.log('onAction');
  };

  useEffect(() => {
    setInterval(async () => {
      try {
        if (isCallPagePing) {
          conObj.post('UserInactiveHistory/ping').then((res) => {
            if (res.status === 200) {
              console.log(`PING: ${moment().format('YYYY-MM-DD HH:mm')}`);
              setPingInterval(res.data.nextPingIntervalSecs * 1000);
            }
          });
        }
      } catch (err) {
        console.log(err.message);
      }
    }, pingInterval);
    // fetchPendingLoanCount();
    // const timer = setInterval(() => {
    //   fetchPendingLoanCount();
    // }, 15000); test
    // return () => clearInterval(timer);
  }, []);

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * 2, // 2min
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  });

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>

      {isOpenCall && <CallClient isOpenCall={isOpenCall} handleCloseCall={handleCloseCall} />}

      <Hardware isOpenHardware={isOpenHardware} handleCloseHardware={handleCloseHardware} />

      <VoipInstruction />

      <CustomConfirm />
    </Box>
  );
};

export default DashboardLayout;

const StyledPhoneKeyPads = styled(Box)(({ theme }) => ({
  width: 50,
  height: 50,
  background: theme.palette.action.focus,
  '&:hover': {
    background: theme.palette.action.hover,
    transform: 'scale(1.1)',
  },
  marginLeft: 'auto',
  marginRight: 'auto',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CallClient = ({ isOpenCall, handleCloseCall }) => {
  const [toCall, setToCall] = useState('');

  const [showTooltip, setShowTooltip] = useState(false);

  const dispatch = useDispatch();

  const numberRef = useRef(null);

  const {enqueueSnackbar} = useSnackbar();

  const handleCallerNumber = (e) => {
    setToCall(e.target.value);
  };

  const handleKeypad = (val) => {
    if (toCall.length < 11) {
      setToCall((prevState) => `${prevState + val}`);
    }
  };

  const handleMouseEnter = () => {
    if(numberRef.current?.querySelector('ctc')) setShowTooltip(true);
  }

  const handleMouseLeave = () => {
    setShowTooltip(false);
  }

  const handleCall = () => {
    if(toCall.length < 7 || /[a-zA-Z]/g.test(fMobileNumber(toCall))) return enqueueSnackbar('Invalid number entered!', {variant: 'error'});
    const element = numberRef.current?.querySelector('ctc');
    if(element) element?.click();
    else dispatch(openVoipInstruction());
  }

  return (
    <CustomModal open={isOpenCall} onClose={handleCloseCall} TransitionComponent={Transition} fullWidth maxWidth="xs">
      <DialogTitle>Enter Phone Number</DialogTitle>

      <Box sx={{ width: 182, mx: 'auto', textAlign: 'center', mt: 3 }}>
        <TextField
          type="tel" // setting this to number wont apply maxLength
          name="toCall"
          label="Phone Number"
          placeholder="Enter Phone Number"
          variant="outlined"
          size="small"
          fullWidth
          value={toCall}
          onChange={(e) => handleCallerNumber(e)}
          inputProps={{ maxLength: 11 }}
          key={1}
        />
        <ClickToCall number={toCall} customRef={numberRef} isHidden={true} />

        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('1')}>
              1
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('2')}>
              2
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('3')}>
              3
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('4')}>
              4
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('5')}>
              5
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('6')}>
              6
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('7')}>
              7
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('8')}>
              8
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('9')}>
              9
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4} />

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <StyledPhoneKeyPads component={'span'} onClick={() => handleKeypad('0')}>
              0
            </StyledPhoneKeyPads>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4} />
        </Grid>
      </Box>

      <DialogActions sx={{ mx: 'auto' }}>
        <Tooltip title={`Call ${toCall} with Linkus Web Client`} placement="top" open={showTooltip}>
          <Box
            sx={{
              width: 50,
              height: 50,
              borderRadius: '50%',
              background: '#00AB55',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                background: '#05703b',
                transform: 'scale(1.1)',
              },
              cursor: 'pointer',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleCall}
          >
            <Iconify icon={'fluent:call-48-filled'} sx={{ fontSize: 30, color: 'white' }} />
          </Box>
        </Tooltip>
        <Box
          sx={{
            position: 'absolute',
            mr: '-45px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
              transform: 'scale(1.1)',
            },
          }}
          onClick={() => setToCall((prevState) => prevState.substring(0, prevState.length - 1))}
        >
          <Iconify icon={'fa6-solid:delete-left'} sx={{ fontSize: 30 }} />
        </Box>
      </DialogActions>
    </CustomModal>
  );
};

const Hardware = ({ isOpenHardware, handleCloseHardware }) => (
  <DialogAnimate open={isOpenHardware} onClose={handleCloseHardware}>
    <DialogTitle>Hardware Setting</DialogTitle>

    <Box sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            type="textarea"
            name="micDevices"
            label="Mic Devices"
            placeholder="Enter Phone Number"
            variant="outlined"
            size="small"
            fullWidth
            rows={3}
            value={'Unknown Audio Output Device 1'}
            // value={toCall}
            // onChange={(e) => setToCall(e.target.value)}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            type="textarea"
            name="speakerDevices"
            label="Speaker Devices"
            placeholder="Enter Phone Number"
            variant="outlined"
            size="small"
            fullWidth
            value={'Unknown Audio Output Device 1'}
            // value={toCall}
            // onChange={(e) => setToCall(e.target.value)}
          />
        </Grid>
      </Grid>

      <Button size="small" variant="contained" color="primary" sx={{ mt: 2 }}>
        Load Devices
      </Button>

      <Button
        size="small"
        variant="outlined"
        color="primary"
        sx={{ mt: 2, ml: 1 }}
        onClick={() => handleCloseHardware()}
      >
        Cancel
      </Button>
    </Box>
  </DialogAnimate>
);
