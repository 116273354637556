import { createSlice } from '@reduxjs/toolkit';
import { COLOR_OPTIONS } from '../../sections/@dashboard/calendar/CalendarForm';
// utils
import axios from '../../utils/axios';
import conObj from '../../utils/connection-assistant';
import getMoment, { getDateTime, getDateYearFirst } from '../../utils/getMoment';
//
import { dispatch, store, useSelector } from '../store';

// ----------------------------------------------------------------------

const toCalendarObj = (event) => {
  if(!event) return event;
  const isEditable = event.eventTypes !== "LoanDueDate";
  return {
    allDay: event.isAllDay,
    end: event.eventEndAt,
    id: parseInt(event.entityId),
    start: event.eventStartAt,
    textColor: COLOR_OPTIONS[event.eventTypes].color,
    title: event.title,
    // editable: isEditable,
  };
}

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  isOpenCall: false,
  isOpenHardware: false,
  currentDate: getDateTime()
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // GET CURRENT DATE FILTER
    setCurrentDate(state, action) {
      state.currentDate = getDateTime(action.payload);
    },

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // OPEN CALL
    openCall(state) {
      state.isOpenCall = true;
    },

    // CLOSE CALL
    closeCall(state) {
      state.isOpenCall = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = toCalendarObj(action.payload);
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = toCalendarObj(action.payload);
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      // state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent, setCurrentDate } = slice.actions;

// ----------------------------------------------------------------------

export function getEvents() {
  const {currentDate} = store.getState().calendar;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const month = getMoment(currentDate).format("M");
      const year = getMoment(currentDate).year();
      const response = await conObj.get('Dashboard/calendars', {params: {month, year}});
      const events = response.data.map((event) => toCalendarObj(event));
      dispatch(slice.actions.getEventsSuccess(events));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let {start, end} = newEvent;
      if(newEvent.allDay) {
        start = getMoment(newEvent.start).startOf("day").valueOf();
        end = getMoment(newEvent.start).endOf("day").valueOf();
      }

      const _event = {
        isAllDay: newEvent.allDay,
        content: newEvent.description,
        title: newEvent.title,
        isPublic: newEvent.isPublic,
        eventStartAt: getDateTime(start),
        eventEndAt: getDateTime(end),
        textColor: newEvent.textColor
      }
      const response = await conObj.post(`Dashboard/calendar`, _event);
      console.log(response);
      // dispatch(slice.actions.createEventSuccess({..._event, id: response.data.calendarEventId}));
      dispatch(getEvents());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, updateEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let {start, end} = updateEvent;
      if(updateEvent.allDay) {
        start = getMoment(updateEvent.start).startOf("day").valueOf();
        end = getMoment(updateEvent.start).endOf("day").valueOf();
      }
      
      const _event = {
        calendarEventId: eventId,
        isAllDay: updateEvent.allDay,
        content: updateEvent.description,
        title: updateEvent.title,
        isPublic: updateEvent.isPublic,
        eventStartAt: getDateTime(start),
        eventEndAt: getDateTime(end),
        textColor: updateEvent.textColor
      }

      await conObj.put(`Dashboard/calendar/${eventId}`, _event);
      // dispatch(slice.actions.updateEventSuccess(_event));
      dispatch(getEvents());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await conObj.delete(`Dashboard/calendar/${eventId}`);
      // dispatch(slice.actions.deleteEventSuccess({ eventId }));
      dispatch(getEvents());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async () => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime(),
      })
    );
  };
}
