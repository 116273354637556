import { Box, CardMedia } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal from './CustomModal';
import Image from './Image';
import LightboxModal from './LightboxModal';

const DEFAULT_MEDIA_SELECTED = {
  mediaType: '',
  signedUrl: '',
};

const MediaPreviewModal = ({ media = DEFAULT_MEDIA_SELECTED, open = false, handleClose = () => {} }) => {
  const [isLandscape, setIsLandscape] = useState(true);

  const [selectedMedia, setSelectedMedia] = useState(DEFAULT_MEDIA_SELECTED);

  useEffect(() => {
    if (media == null) setSelectedMedia(DEFAULT_MEDIA_SELECTED);
    else setSelectedMedia(media);
  }, [media]);

  const onMediaLoad = ({ target: img, video }) => {
    const mHeight = img.offsetHeight || video.videoHeight;
    const mWidth = img.offsetWidth || video.videoWidth;
    if (mHeight > mWidth + 100) setIsLandscape(false);
    else setIsLandscape(true);
  };

  return selectedMedia.mediaType === 'video' ? (
    <CustomModal scroll="body" fullWidth maxWidth={isLandscape ? 'lg' : 'sm'} open={open} onClose={handleClose}>
      <CardMedia
          autoPlay
          component="video"
          controls
          onLoadedMetadata={onMediaLoad}
          sx={{height: isLandscape ? "auto" : "90vh", backgroundColor: "black"}}
          src={selectedMedia.signedUrl}
        />
    </CustomModal>
  ) : (
    <LightboxModal
      images={[selectedMedia.signedUrl]}
      mainSrc={selectedMedia.signedUrl}
      isOpen={open}
      onCloseRequest={handleClose}
    />
  );
};

MediaPreviewModal.propTypes = {
  media: PropTypes.instanceOf(DEFAULT_MEDIA_SELECTED),
  open: PropTypes.bool,
  handleClose: PropTypes.any,
};

export default MediaPreviewModal;
