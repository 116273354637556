import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton, MobileDatePicker } from '@mui/lab';
import { useSnackbar } from 'notistack';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
// hooks
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock
import { countries } from '../../_mock';
import { useDispatch, useSelector } from '../../redux/store';
// routes
import { PATH_SETTING } from '../../routes/paths';

import {
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedActions,
} from '../../components/table';

import AnnouncementNotifTableRow from './AnnouncementNotifTableRow';
import conObj from '../../utils/connection-assistant';
import { getDateTime, getDateYearFirst } from '../../utils/getMoment';
import { APP_ORIGIN } from './AnnouncementNotifForm';

const FILTERS = {
  title: '',
  isActive: '',
  isPublishToSubGroupType: [],
  // name: '',
  publishDateStart: null,
  publishDateEnd: null,
  isToAllCustomers: '',
  appOrigin: '',
};

const TABLE_HEAD = [
  { id: 'appOrigin', label: 'Apps Origin', minWidth: 100 },
  { id: 'title', label: 'Title', minWidth: 100 },
  { id: 'isToAllCustomers', label: 'Publish To', minWidth: 100 },
  { id: 'publishDate', label: 'Publish Date', minWidth: 160 },
  { id: 'createdBy', label: 'Created By', minWidth: 100 },
  { id: 'lastUpdated', label: 'Last Updated', minWidth: 160 },
  { id: 'isActive', label: 'Status', minWidth: 100 },
  {
    id: 'action',
    label: 'Action',
    width: 20,
    sx: {
      position: 'sticky',
      right: 0,
      zIndex: 1,
      backgroud: '#637381',
    },
  },
];

export const SUB_GROUPS = [
  {title: "Good Pay Loan", value: "good_pay_loan"},
  {title: "Bad Pay Loan", value: "bad_pay_loan"},
  {title: "High Risk Customer", value: "high_risk_customer"}
];

const AnnouncementNotifList = () => {
  const [filters, setFilters] = useState(FILTERS);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { themeStretch } = useSettings();

  const [tableData, setTableData] = useState([]);

  const [filterName, setFilterName] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [isOpenModal, setOpenModal] = useState(false);

  const [total, setTotal] = useState(0);

  const {enqueueSnackbar} = useSnackbar();

  const [searchFilters, setSearchFilters] = useState(filters);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  useEffect(() => {
    //
    fetchAnnouncements();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if(filters !== searchFilters) fetchAnnouncements();
  }, [searchFilters]);

  useEffect(() => {
    const keyDownHandler = event => {

      if (event.key === 'Enter') {
        event.preventDefault();
        submitSearch();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [filters]);

  const handleChange = (e) => {
    setFilters((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteRow = (id) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const reFormatFilter = () => {
    const replacer = {
      publishDateStart: filters.publishDateStart ? getDateYearFirst(filters.publishDateStart) : null,
      publishDateEnd: filters.publishDateEnd ? getDateYearFirst(filters.publishDateEnd) : null,
      isPublishToSubGroupType: filters.isPublishToSubGroupType.map(group => (group.value)).join(",")
    };

    return {...searchFilters, ...replacer};
  }

  const fetchAnnouncements = () => {
    setIsLoading(true);
    conObj.get("Announcement", {
      params: {
          ...reFormatFilter(),
        page: page+1,
        size: rowsPerPage,
      }
    }).then((response) => {
      setTableData(response.data.items);
      setTotal(response.data.count);
    }).catch((error) => {
      if(error.response.data?.title) enqueueSnackbar(error.response.data.title);
      else console.log(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  // const handleEditRow = (id) => {
  //   navigate(PATH_DASHBOARD.eCommerce.edit(paramCase(id)));
  // };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const denseHeight = dense ? 60 : 80;

  const isNotFound = (!dataFiltered.length && !!filterName) || (!isLoading && !dataFiltered.length);

  const handleDateChange = (val, name) => {
    setFilters((prevState) => ({ ...prevState, [name]: val }));
  };

  const submitSearch = () => {
    setPage(0);
    setSearchFilters((prevState) => ({...prevState, ...filters}));
  };

  const handleResetFilters = () => {
    setPage(0);
    setFilters(prevState => ({...prevState, ...FILTERS}));
    setSearchFilters(prevState => ({...prevState, ...FILTERS}));
  }

  return (
    <>
    <Page title="Announcement Notification List">
      <Typography variant="h4" sx={{ mb: 2 }}>
        Announcement Notification List
      </Typography>
      <Card>
        <CardHeader title="Search" />

        <Box component={"form"} onSubmit={(e) => {e.preventDefault();submitSearch()}}>
        <Container maxWidth={themeStretch ? false : 'xl'} sx={{ mt: 3, mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <TextField
                name="title"
                value={filters.title}
                onChange={(e) => handleChange(e)}
                label="Title"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <TextField
                name="isActive"
                value={filters.isActive}
                onChange={(e) => handleChange(e)}
                label="Status"
                placeholder="Status"
                select
                fullWidth
                SelectProps={{ native: true }}
                size="small"
                // error={!!error}
                // helperText={error?.message}
                // {...other}
              >
                <option value="" />
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </TextField>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <MobileDatePicker
                label="Date From"
                value={filters.publishDateStart}
                inputFormat="dd/MM/yyyy"
                onChange={(v) => handleDateChange(v, 'publishDateStart')}
                renderInput={(params) => <TextField {...params} fullWidth variant="outlined" size="small" />}
              />
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <MobileDatePicker
                label="Date To"
                value={filters.publishDateEnd}
                inputFormat="dd/MM/yyyy"
                onChange={(v) => handleDateChange(v, 'publishDateEnd')}
                minDate={filters.publishDateStart || null}
                // maxDate={new Date()}
                renderInput={
                  (params) => 
                    <TextField {...params} 
                      fullWidth 
                      variant="outlined" 
                      size="small"
                    />
                }
              />
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <TextField
                name="isToAllCustomers"
                value={filters.isToAllCustomers}
                onChange={(e) => handleChange(e)}
                label="Published to All"
                placeholder="Published to All"
                select
                fullWidth
                SelectProps={{ native: true }}
                size="small"
              >
                <option value="" />
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </TextField>
            </Grid>

            {!filters.isToAllCustomers || filters.isToAllCustomers === "false" ? 
              <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                <Autocomplete
                  multiple
                  value={filters.isPublishToSubGroupType}
                  options={SUB_GROUPS}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, value) => handleChange({target: {name:"isPublishToSubGroupType", value}})}
                  // isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sub Group Types"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                /> 
              </Grid>
            : ""}

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <TextField
                name="appOrigin"
                value={filters.appOrigin}
                onChange={(e) => handleChange(e)}
                label="App Origin"
                placeholder="App Origin"
                select
                fullWidth
                SelectProps={{ native: true }}
                size="small"
                // error={!!error}
                // helperText={error?.message}
                // {...other}
              >
                <option value="" />
                {Object.keys(APP_ORIGIN).map((key, idx) => <option key={idx} value={key}>{APP_ORIGIN[key]}</option>)}
              </TextField>
            </Grid>
          </Grid>

          <Stack alignItems="flex-start" sx={{ mt: 3 }}>
            <div>
              <LoadingButton
                type="submit"
                variant="contained" 
                loading={isLoading}
                disabled={isLoading}
                color="primary"
                // onClick={() => submitSearch()}
              >
                Search
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                loading={isLoading}
                disabled={isLoading}
                color="primary"
                sx={{ ml: 1 }}
                onClick={() => handleResetFilters()}
              >
                Reset
              </LoadingButton>
            </div>
          </Stack>
        </Container>
        </Box>
      </Card>

      <Card sx={{ mt: 3 }}>
        {/* <ProductTableToolbar filterName={filterName} onFilterName={handleFilterName} /> */}

        <Box sx={{ pt: 3, pl: 3 }}>
          <Button variant="contained" color="primary" onClick={() => navigate(PATH_SETTING.announcementNotifForm)}>
            Add New
          </Button>
        </Box>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 1050, position: 'relative', overflow: 'initial' }}>
            {/* {selected.length > 0 && (
              <TableSelectedActions
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
                actions={
                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                      <Iconify icon={'eva:trash-2-outline'} />
                    </IconButton>
                  </Tooltip>
                }
              />
            )} */}

            <Table size={'small'} sx={{ mt: 3 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                // numSelected={selected.length}
                onSort={onSort}
                // onSelectAllRows={(checked) =>
                //   onSelectAllRows(
                //     checked,
                //     tableData.map((row) => row.id)
                //   )
                // }
              />

              <TableBody>
                {
                  // (isLoading ? [...Array(rowsPerPage)] : dataFiltered)
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  dataFiltered.length ? 
                  dataFiltered.map((row, index) =>
                    (
                      <AnnouncementNotifTableRow
                        key={index}
                        row={row}
                        // selected={selected.includes(row.id)}
                        // onSelectRow={() => onSelectRow(row.id)}
                        onDeleteRow={() => fetchAnnouncements()}
                        // onEditRow={() => handleEditRow(row.name)}
                      />
                    )
                  ) : !isNotFound && <TableSkeleton rowNo={3} sx={{ height: denseHeight }} />
                }

                {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            SelectProps={{disabled: isLoading}}
            backIconButtonProps={isLoading ? {disabled: true} : undefined}
            nextIconButtonProps={isLoading ? {disabled: true} : undefined}
          />

          {/* <FormControlLabel
            control={<Switch checked={dense} onChange={onChangeDense} />}
            label="Dense"
            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
          /> */}
        </Box>
      </Card>
      {/* <DialogAnimate open={isOpenModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Add New Announcement/Notification</DialogTitle>
        <ApplyLoanForm event={{} || {}} onCancel={() => setOpenModal(false)} />
      </DialogAnimate> */}
      </Page>
    </>
  );
};

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  return tableData;
}

export default AnnouncementNotifList;
