import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Box, Stack, AppBar, Toolbar, Typography } from '@mui/material';
// hooks
import { useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import conObj from '../../../utils/connection-assistant';
import { useDispatch, useSelector } from '../../../redux/store';
import {
  fetchNotifications,
  setPendingManualBankingCount,
  setUnreadCount,
  setUnreadInactiveLogCount,
} from '../../../redux/slices/notification';
import { setPendingLoanCount } from '../../../redux/slices/loan';
import { openConfirm } from '../../../redux/slices/confirm';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [isUpdatingLunch, setUpdatingLunch] = useState(false);

  const [isHavingLunch, setHavingLunch] = useState(false);

  const [isFinishedLunch, setFinishedLunch] = useState(false);

  const { isFetching } = useSelector((state) => state.notification);

  const lunchBreak = () => {
    setUpdatingLunch(true);
    conObj
      .post('UserBreakHistory/toggle-lunch')
      .then((res) => {
        if (res.data.addedLunch) {
          enqueueSnackbar('You are now on lunch break');
          setHavingLunch(true);
        } else if (res.data.endedLunch) {
          setHavingLunch(false);
          setFinishedLunch(true);
          enqueueSnackbar('You have ended your lunch break, Welcome back');
        }
      })
      .catch((err) => {
        console.log(`Error: ${err.message}`);
        if (err.response?.status === 400) {
          enqueueSnackbar(err.response.data.message, { variant: 'error' });
        } else {
          enqueueSnackbar(err.message, { variant: 'error' });
        }
      })
      .finally(() => setUpdatingLunch(false));
  };

  const getBadges = () => {
    conObj
      .get('Lookup/badges')
      .then((response) => {
        const {
          pendingLoanCount,
          unreadNotificationCount,
          isHavingLunchBreak,
          finishedLunchToday,
          unreadInactiveLogCount,
          pendingManualBankingCount,
        } = response.data;
        dispatch(setPendingLoanCount(pendingLoanCount));
        dispatch(setUnreadInactiveLogCount(unreadInactiveLogCount));
        dispatch(setPendingManualBankingCount(pendingManualBankingCount));
        dispatch(setUnreadCount(unreadNotificationCount));
        setHavingLunch(isHavingLunchBreak);
        setFinishedLunch(finishedLunchToday);
      })
      .catch((err) => {
        console.log(`Error: ${err.message}`);
      });
  };

  const handleEmergency = async () =>
    conObj
      .post('UserEmergencyHistory/trigger')
      .then((response) => {
        // do nothing as the server no longer accepts requests
      })
      .catch((error) => enqueueSnackbar('Emergency did not trigger. Please try again!', { variant: 'error' }));

  useEffect(() => {
    getBadges();

    const timer = setInterval(() => {
      getBadges();
    }, 15000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    // in some cases, the google translate script adds a style to the opening html tag.
    // this added style disables scrolling.
    // the next 3 lines removes this added style in order to re-enable scrolling.
    if (window.document.scrollingElement.hasAttribute('style')) {
      window.document.scrollingElement.setAttribute('style', '');
    }

    const addScript = document.createElement('script');
    addScript.setAttribute('src', 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const googleTranslateElementInit = () =>
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
      },
      'google_translate_element'
    );

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <div id="google_translate_element" />
          <Button
            color="error"
            variant="contained"
            size="small"
            startIcon={<Iconify icon="fluent:alert-urgent-16-filled" />}
            onClick={() =>
              openConfirm(
                <Typography variant="title2">Warning! Trigerring Emergency</Typography>,
                handleEmergency,
                null,
                'Once emergency is triggered, the system will be disabled and inaccessible. Continue?',
                false,
                true
              )
            }
          >
            Emergency
          </Button>
          <LoadingButton
            color="warning"
            variant="outlined"
            onClick={() => lunchBreak()}
            size="small"
            startIcon={<Iconify icon="fluent:food-24-filled" />}
            disabled={isFinishedLunch || isUpdatingLunch}
            loading={isUpdatingLunch}
          >
            {isHavingLunch ? 'End Lunch' : 'Lunch'}
          </LoadingButton>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            startIcon={<Iconify icon="bi:chat-right-text-fill" />}
          >
            Live Chat
          </Button>
          <Button color="primary" variant="outlined" size="small" startIcon={<Iconify icon="clarity:flag-solid" />}>
            Malaysia
          </Button>
          <Button
            onClick={() => navigate('/calendar')}
            color="primary"
            variant="outlined"
            size="small"
            startIcon={<Iconify icon="dashicons:calendar" />}
          >
            Calendar
          </Button>
          <LanguagePopover />
          <NotificationsPopover />
          {/* <ContactsPopover /> */}
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
