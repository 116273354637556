import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isOpenCall: false,
  isOpenHardware: false,
  isOpenVoipInstruction: false,
  isCallPagePing: false
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // OPEN CALL
    openCall(state) {
      console.log('enter call')
      state.isOpenCall = true;
    },

    // CLOSE CALL
    closeCall(state) {
      console.log('enter call close')
      state.isOpenCall = false;
    },

    // OPEN VOIP INSTRUCTION
    openVoipInstruction(state) {
      state.isOpenVoipInstruction = true;
    },

    // CLOSE VOIP INSTRUCTION
    closeVoipInstruction(state) {
      state.isOpenVoipInstruction = false;
    },

    // OPEN HARDWARE
    openHardware(state) {
      state.isOpenHardware = true;
    },

    // CLOSE HARDWARE
    closeHardware(state) {
      state.isOpenHardware = false;
    },

    // RUN PING
    runPagePing(state) {
      state.isCallPagePing = true;
    },

    // STOP PING
    stopPagePing(state) {
      state.isCallPagePing = false;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openCall, closeCall, openVoipInstruction, closeVoipInstruction, openHardware, closeHardware, runPagePing, stopPagePing } = slice.actions;
