// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_USER = '/user/';
const ROOTS_LOAN = '/loans';
const ROOTS_REPAYMENT = '/repayment/';
const ROOTS_STAFF = '/staff/';
const ROOTS_CUSTOMER = '/customer/';
const ROOTS_SETTING = '/setting/';
const ROOTS_EXPENSES = '/expenses/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_USER = {
  root: ROOTS_USER,
  googleAuth: path(ROOTS_USER, 'google-authenticator'),
  dutyScheduler: path(ROOTS_USER, 'duty-scheduler'),
  manageAdmin: path(ROOTS_USER, 'manage-admin'),
  manageGroup: path(ROOTS_USER, 'manage-group'),
  groupForm: path(ROOTS_USER, 'manage-group/form'),
  adminForm: path(ROOTS_USER, 'manage-admin/form'),
  adminAmendment: path(ROOTS_USER, 'admin-amendment'),
  activityLog: path(ROOTS_USER, 'activity-log'),
  inactiveLog: path(ROOTS_USER, 'inactive-log'),
  accountSettings: path(ROOTS_USER, 'account'),
};

export const PATH_LOAN = {
  root: ROOTS_LOAN,
  customerInfo: path(ROOTS_LOAN, '/customer/info'),
  // active: path(ROOTS_LOAN, 'active'),
  // listing: path(ROOTS_LOAN, 'listing'),
  // overdueListing: path(ROOTS_LOAN, 'overdue-listing'),
  // badPay: path(ROOTS_LOAN, 'bad-pay'),
  // nonPerforming: path(ROOTS_LOAN, 'non-performing'),
};

export const PATH_REPAYMENT = {
  root: ROOTS_REPAYMENT,
  log: path(ROOTS_REPAYMENT, 'log'),
  logAudit: path(ROOTS_REPAYMENT, 'audit-log'),
  manualBanking: path(ROOTS_REPAYMENT, 'manual-banking'),
};

export const PATH_STAFF = {
  root: ROOTS_STAFF,
  commission: path(ROOTS_STAFF, 'commission'),
  reward: path(ROOTS_STAFF, 'reward'),
  approval: path(ROOTS_STAFF, 'approval'),
};

export const PATH_CUSTOMER = {
  root: ROOTS_CUSTOMER,
  list: path(ROOTS_CUSTOMER, 'list'),
  edit: path(ROOTS_CUSTOMER, 'edit'),
  info: path(ROOTS_CUSTOMER, 'info'),
  kycVerifyList: path(ROOTS_CUSTOMER, 'kyc-verify-list'),
  kycInfo: path(ROOTS_CUSTOMER, 'info/kyc'),
  blacklisted: path(ROOTS_CUSTOMER, 'blacklisted'),
  commissionWithdrawalListing: path(ROOTS_CUSTOMER, 'commission-withdrawal-listing'),
};

export const PATH_EXPENSES = {
  root: ROOTS_EXPENSES,
};

export const PATH_SETTING = {
  root: ROOTS_SETTING,
  loanPackage: path(ROOTS_SETTING, 'loan-package'),
  companyBankAccounts: path(ROOTS_SETTING, 'company-bank-accounts'),
  mobileApps: path(ROOTS_SETTING, 'mobile-apps'),
  promotion: path(ROOTS_SETTING, 'promotion'),
  announcementNotifList: path(ROOTS_SETTING, 'announcement-notif-list'),
  announcementNotifForm: path(ROOTS_SETTING, 'announcement-notif-list/add-new'),
  exportHistory: path(ROOTS_SETTING, 'export-history'),
  blacklistedMobile: path(ROOTS_SETTING, 'blacklisted-mobile'),
  hardware: path(ROOTS_SETTING, 'hardware'),
  call: path(ROOTS_SETTING, 'call'),
  whatsAppHandler: path(ROOTS_SETTING, 'whatsapp-handler'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
