import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import { fMobileNumber } from '../utils/formatNumber';
import Iconify from './Iconify';
import { useDispatch } from '../redux/store';
import { openVoipInstruction } from '../redux/slices/settings';

function ClickToCall({
  number = '',
  callback = () => {},
  isIconLeft = true,
  tooltipPlacement = 'top',
  customRef = { current: null },
  isHidden = false,
  ...others
}) {
  const dispatch = useDispatch();

  const numberRef = useRef(null);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleCall = () => {
    const ctcEl = numberRef.current.querySelector('ctc');
    if (!ctcEl) {
      dispatch(openVoipInstruction());
    } else {
      ctcEl?.click();
      callback();
    }
  };

  const handleMouseEnter = () => {
    if (numberRef.current?.querySelector('ctc')) setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const mergeRefs =
    (...refs) =>
    (node) => {
      refs.forEach((ref) => {
        ref.current = node;
      });
    };

  // return (
  //   <Typography variant="caption" {...others} sx={{ display: isHidden ? 'none' : 'block' }}>
  //     {number}
  //   </Typography>
  // );

  if (number.toString().length < 7 || /[a-zA-Z]/g.test(fMobileNumber(number))){
    return (
      <Typography variant="caption" {...others} sx={{display: isHidden ? 'none' : 'block'}}>
        {number}
      </Typography>
    );
  }

  return (
    <>
      {!isHidden && (
        <Tooltip
          title={`Call ${fMobileNumber(number)} with Linkus Web Client`}
          open={showTooltip}
          placement={tooltipPlacement}
          followCursor
        >
          <Typography
            variant="caption"
            color={'primary.main'}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleCall}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            {...others}
          >
            {isIconLeft && <Iconify icon="ant-design:phone-filled" sx={{ my: 'auto' }} />}
            &nbsp;
            {fMobileNumber(number)}
            &nbsp;
            {!isIconLeft && <Iconify icon="ant-design:phone-filled" onClick={stopPropagation} sx={{ my: 'auto' }} />}
          </Typography>
        </Tooltip>
      )}
      <Box component={'span'} ref={mergeRefs(numberRef, customRef)} className="clickToCall">
        {fMobileNumber(number)}
      </Box>
    </>
  );
}

ClickToCall.propTypes = {
  number: PropTypes.any,
  callback: PropTypes.func,
  isIconLeft: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
  customRef: PropTypes.any,
  isHidden: PropTypes.bool,
};

export default ClickToCall;
