import { createSlice } from '@reduxjs/toolkit';
// utils
import conObj from '../../utils/connection-assistant';
//
import { dispatch } from '../store';

export const INSTALLMENT_STATUS = [
  {
    value: 1,
    name: "Has Installment"
  },
  {
    value: 2,
    name: "Has Installment - Never Paid"
  },
  {
    value: 3,
    name: "Has Installment - Partially Paid"
  },
  {
    value: 4,
    name: "Has Installment - Fully Repaid"
  },
  {
    value: 5,
    name: "Has Installment - Overdue"
  },
  {
    value: 6,
    name: "Has Installment - Due Today"
  },
  {
    value: 7,
    name: "Has Installment - Due Tomorrow"
  },
  {
    value: 9,
    name: "No Installment"
  },
];

export const LOAN_LIST_FILTERS = {
  appliedDateStart: null,
  appliedDateEnd: null,
  disbursedDateStart: null,
  disbursedDateEnd: null,
  customerId: '',
  customerName: '',
  customerIc: '',
  customerPhoneNumber: '',
  adminInChargeUserId: null,
  collectorInChargeUserId: null,
  loanId: '',
  applicationStatus: '',
  loanCollectionStatus: '',
  onTimeRepayment: '',
  customerCountry: '',
  isBlacklist: '',
  // loanNo: '', // TODO: MISSING,
  customerType: '',
  isManualLoan: '',
  // manualLoanCompanyName: '',
  installmentStatus: '',
};

export const OVERDUE_FILTERS = {
  appliedDateStart: null,
  appliedDateEnd: null,
  expiryDateStart: null,
  expiryDateEnd: null,
  customerId: '',
  customerName: '',
  customerIc: '',
  customerPhonenumber: '',
  daysOverdue: '',
  adminInChargeUserId: null,
  collectorInChargeUserId: null,
  loanId: '',
  loanStatus: '',
  callUpdateStatus: '', // TODO: Missing
  daysAssigned: '',
  isManualLoan: '',
  // manualLoanCompanyName: '',
  installmentStatus: '',
};

const initialState = {
  isLoading: false,
  loanPackages: [],
  pendingLoanCount: 0,
  admins: [],
  loanListingFilters: LOAN_LIST_FILTERS,
  overdueListingFilters: OVERDUE_FILTERS,
  paymentGateways: [],
  // manualLoanCompanies: [],
};

const slice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setLoanPackages(state, action) {
      state.loanPackages = action.payload;
    },
    setPendingLoanCount(state, action) {
      state.pendingLoanCount = action.payload;
    },
    setAdmins(state, action) {
      state.admins = action.payload;
    },
    setLoanListingFilters(state, action) {
      state.loanListingFilters = action.payload;
    },
    resetLoanListingFilters(state) {
      state.loanListingFilters = LOAN_LIST_FILTERS;
    },
    setOverdueListingFilters(state, action) {
      state.overdueListingFilters = action.payload;
    },
    resetOverdueListingFilters(state) {
      state.overdueListingFilters = OVERDUE_FILTERS;
    },
    setPaymentGateways(state, action) {
      state.paymentGateways = action.payload;
    },
    setManualLoanCompanies(state, action) {
      state.manualLoanCompanies = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const {
  setPendingLoanCount,
  setLoanListingFilters,
  resetLoanListingFilters,
  setOverdueListingFilters,
  resetOverdueListingFilters,
} = slice.actions;

export const fetchLoanPackages = () => {
  dispatch(slice.actions.setLoading(true));
  return conObj.get('Lookup/loan-packages').then((response) => {
    dispatch(slice.actions.setLoading(false));
    dispatch(slice.actions.setLoanPackages(response.data));
  });
};

export const fetchAdmins = () =>
  conObj.get('Lookup/admins').then((response) => dispatch(slice.actions.setAdmins(response.data.items)));

export const fetchPaymentGateways = () =>
  conObj
    .get('Lookup/payment-gateways')
    .then((response) => dispatch(slice.actions.setPaymentGateways(response.data.items)));

// Constants
export const BANKS = [
  'Affin Bank Berhad',
  'Alliance Bank',
  'AmBank (M) Berhad',
  'BNP Paribas',
  'Bangkok Bank Berhad',
  'Bank of America',
  'Bank of China',
  'Bank of Tokyo-Mitsubishi UFJ',
  'Bank Rakyat',
  'CIMB Bank Berhad',
  'Citibank Berhad',
  'Deutsche Bank',
  'HSBC Bank',
  'Hong Leong Bank Berhad',
  'India International Bank',
  'Industrial and Commercial Bank of China',
  'J.P. Morgan Chase Bank Berhad',
  'Malayan Banking Berhad',
  'Mizuho Bank',
  'National Bank of Abu Dhabi',
  'OCBC Bank',
  'Public Bank Berhad',
  'RHB Bank Berhad',
  'Standard Chartered Bank',
  'Sumitomo Mitsui Banking Corporation',
  'The Bank of Nova Scotia Berhad',
  'The Royal Bank of Scotland Berhad',
  'United Overseas Bank',
  'Affin Islamic Bank Berhad',
  'Al Rajhi Banking and Investment Corporation',
  'Alliance Islamic Bank Berhad',
  'AmBank Islamic Berhad',
  'Asian Finance Bank Berhad',
  'Bank Islam',
  'Bank Muamalat',
  'CIMB Islamic Bank Berhad',
  'HSBC Amanah',
  'Hong Leong Islamic Bank Berhad',
  'Kuwait Finance House',
  'Maybank Islamic Berhad',
  'OCBC Al-Amin Bank Berhad',
  'Public Islamic Bank Berhad',
  'RHB Islamic Bank Berhad',
  'Standard Chartered Saadiq Berhad',
  'Affin Hwang Investment Bank Berhad',
  'Alliance Investment Bank Berhad',
  'AmInvestment Bank Berhad',
  'CIMB Investment Bank Berhad',
  'Hong Leong Investment Bank Berhad',
  'KAF Investment Bank Berhad',
  'Kenanga Investment Bank Berhad',
  'MIMB Investment Bank Berhad',
  'Maybank Investment Bank Berhad',
  'Public Investment Bank Berhad',
  'RHB Investment Bank Berhad',
  'Al Rajhi Banking and Investment Corporation',
  'Deutsche Bank Aktiengesellschaft',
  'PT Bank Muamalat Indonesia, Tbk',
  'Co-op Bank Pertama',
  'Bank Pertanian (Agrobank)',
  'Bank Simpanan Nasional',
  'Bank Kerjasama Malaysia Berhad',
  'MBSB Bank Berhad',
  'TnG eWallet',
];

export const LOAN_APPLICATION_STATUS = {
  Submitted: 9,
  'Pending Approval': 0,
  Rejected: -1,
  Approved: 1,
  Disbursed: 2,
  Closed: 3,
};

export const LOAN_STATUS = {
  Active: 1,
  'Repayment - Partial': 2,
  'Repayment - Overdue': 3,
  'Non-Performing Loan': 4,
};

export const LOAN_APPLICATION_STATUS_COLORS = {
  Submitted: 'info',
  'Pending Approval': 'warning',
  Rejected: 'error',
  Approved: 'success',
  Disbursed: 'secondary',
  Closed: 'default',
};

export const LOAN_CALL_STATUS = {
  isComplete: 'Complete',
  isPartialPayment: 'Partial Payment',
  isNoPickup: 'No Pickup',
  isPhoneOff: 'Phone Off',
  isDelay: 'Delay',
  isFollowUpLater: 'Follow Up Later',
  isBlocked: 'Blocked',
  isNotInService: 'Not In Service',
};

export const OT_REPAYMENT = {
  0: 'N/A',
  1: 'Yes',
  2: 'No',
};

export const getKeyByValue = (obj, value) => {
  let retValue = '-';
  Object.keys(obj).forEach((key) => {
    if (obj[key] === value) retValue = key;
  });
  return retValue;
};

export const fetchCustomerCompanies = () =>
  conObj
    .get('Lookup/customer-companies')
    .then((response) => dispatch(slice.actions.setManualLoanCompanies(response.data.items)));
