import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
};

export default function RHFAutocomplete({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
        {...field}
        {...other}
        renderInput={(params) => (
            <TextField
            name={name || ""}
            variant="outlined"
            fullWidth
            error={!!error} helperText={error?.message}
            label={other.label ? other.label : ""}
            {...params}
            />
        )}
        />
      )}
    />
  );
}
