import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useEffect, useRef, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  CircularProgress,
} from '@mui/material';
// utils
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _notifications } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { useDispatch, useSelector } from '../../../redux/store';
import conObj from '../../../utils/connection-assistant';
import {
  fetchNotifications,
  readAllNotification,
  readNotification,
  setNotifications,
} from '../../../redux/slices/notification';
import { getFromNow } from '../../../utils/getMoment';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { notifications, unreadCount } = useSelector((state) => state.notification);

  const dispatch = useDispatch();

  const unread = notifications.filter((notification) => notification.isRead === false);

  const read = notifications.filter((notification) => notification.isRead === true);

  const [open, setOpen] = useState(null);

  const [unreadAmount, setUnreadAmount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const notificationRef = useRef(null);

  const timerRef = useRef(null);

  const handleOpen = (event) => {
    fetchNotifications();
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (open) {
      timerRef.current = setInterval(() => {
        if (!isLoading) fetchNotifications();
      }, 15000);
    } else if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  }, [open]);

  useEffect(() => {
    if (unreadAmount < unreadCount) {
      notificationRef.current.play();
    }
    setUnreadAmount(unreadCount);
  }, [unreadCount]);

  const handleMarkAllAsRead = () => {
    setIsLoading(true);
    dispatch(readAllNotification());
    conObj
      .put('UserNotification/read-all')
      .then(({ data }) => {
        fetchNotifications();
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const handleMarkAsRead = (id) => {
    setIsLoading(true);
    dispatch(readNotification(id));
    conObj
      .put(`UserNotification/read/${id}`)
      .then(({ data }) => {
        fetchNotifications();
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={unreadAmount} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {unreadAmount} unread messages
            </Typography>
          </Box>

          {unreadAmount > 0 && (
            <Tooltip title=" Mark these as read">
              <LoadingButton loading={isLoading} disabled={isLoading} color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </LoadingButton>
            </Tooltip>
          )}
        </Box>

        {unread.length || read.length ? <Divider sx={{ borderStyle: 'dashed' }} /> : <></>}

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {unread.length ? (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Unread
                </ListSubheader>
              }
            >
              {unread.map((notification) => (
                <NotificationItem
                  key={notification.userNotificationId}
                  onRead={handleMarkAsRead}
                  notification={notification}
                />
              ))}
            </List>
          ) : (
            []
          )}

          {read.length ? (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Read
                </ListSubheader>
              }
            >
              {read.map((notification) => (
                <NotificationItem key={notification.userNotificationId} notification={notification} />
              ))}
            </List>
          ) : (
            []
          )}
        </Scrollbar>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        style={{ display: 'none' }}
        ref={notificationRef}
        src={`${window.location.origin}/audio/notification.mp3`}
      />
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.oneOfType([PropTypes.string, Date]),
    id: PropTypes.string,
    isRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
    userNotificationId: PropTypes.number,
  }),
  onRead: PropTypes.func,
};

function NotificationItem({ notification, onRead = () => {} }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.isRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => {
        if (!notification.isRead) onRead(notification.userNotificationId);
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {getFromNow(notification.createdAt) || '-'}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.subtitle)}
      </Typography>
    </Typography>
  );

  // if (notification.type === 'order_placed') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_package.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === 'order_shipped') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_shipping.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_mail.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_chat.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }

  if (notification.type === 'user_idle') {
    return {
      avatar: <Iconify icon={'icon-park-twotone:sleep'} />,
      title,
    };
  }

  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : (
      <Iconify icon={'bxs:bell'} />
    ),
    title,
  };
}
